import React from 'react';
import { Route, Router, Routes } from 'react-router-dom';
import { history } from './history';
import './styles/import.less';
import IndexPage from './pages';
import Error404Page from './pages/error404';
import { ConfigProvider } from 'antd';
import ApiPage from './pages/api';

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1890ff',
        },
      }}
    >
      <Router location={history.location} navigator={history}>
        <Routes>
          <Route path="/index" element={<IndexPage />} />
          <Route path="/" element={<ApiPage />} />
          <Route path="/api" element={<ApiPage />} />
          <Route path="/api/:api" element={<ApiPage />} />
          <Route path="*" element={<Error404Page />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App;
