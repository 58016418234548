import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, Dropdown, /*Breadcrumb,*/ Layout, Menu, Space, theme } from 'antd';
import { availableAPIs } from '../../config';
import Logo from '../Logo/logo';
const { Header, Content, Footer } = Layout;

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

const MainLayout = ({ children, contentClassName, menuSelectedKeys = [] }) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const smallLayout = windowSize.innerWidth < 700;

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const menuItems = Object.keys(availableAPIs).map((key) => {
    return {
      key,
      label: <a href={'/api/' + key}>{availableAPIs[key].label}</a>,
    };
  });

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Layout className={'site-layout ' + (smallLayout ? 'site-layout-sm' : '')}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#1890ff',
              colorBgBase: '#303e4f',
              colorTextBase: '#ffffff',
              algorithm: theme.darkAlgorithm,
            },
          }}
        >
          {smallLayout && (
            <Header
              style={{
                padding: 0,
                backgroundColor: '#303e4f',
              }}
              className="header flex flex-justify-between flex-row px-3"
            >
              <div style={{ height: 32, marginTop: 15 }}>
                <Logo />
              </div>
              <div className="pt-3">
                <Dropdown.Button type="text" menu={{ items: menuItems }} trigger={['click']}>
                  <Space></Space>
                </Dropdown.Button>
              </div>
            </Header>
          )}
          {!smallLayout && (
            <Header
              style={{
                padding: 0,
                backgroundColor: '#303e4f',
              }}
              className="header flex flex-justify-between flex-row px-3"
            >
              <div className="flex-grow flex-row">
                <div className="mr-6" style={{ height: 32, marginTop: 15 }}>
                  <Logo />
                </div>
                <div className="flex-grow">
                  <Menu
                    theme="dark"
                    mode="horizontal"
                    style={{
                      backgroundColor: 'transparent',
                    }}
                    defaultSelectedKeys={menuSelectedKeys}
                    items={menuItems}
                  />
                </div>
              </div>
              <div className={'header-right-buttons'}>
                <Button
                  type="text"
                  onClick={() => {
                    document.location = 'https://dashboard.instasent.com';
                  }}
                >
                  Dashboard
                </Button>
              </div>
            </Header>
          )}
        </ConfigProvider>
        <Content className={(smallLayout ? 'm-2 ' : 'm-4 ') + contentClassName}>{children}</Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          <a href="https://www.instasent.com">Instasent</a> Copyright ©{new Date().getFullYear('Y')}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
