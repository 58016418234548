import logo from '../../images/instasent-logo-v2-white.svg';
import React from 'react';

const Logo = ({ className = '', link = true, ...rest }) => {
  const logoImg = <img className={`${className}`} src={logo} alt="Instasent Logo" {...rest} />;

  if (link) {
    return (
      <a href="/" className="inline-block">
        {logoImg}
      </a>
    );
  }

  return logoImg;
};

export default Logo;
