import React from 'react';
import MainLayout from '../components/Layout/mainLayout';
import { useParams } from 'react-router-dom';
import { availableAPIs } from '../config';
import Error404 from './error404';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

// DOCUMENTATION >>> https://www.npmjs.com/package/swagger-ui-react
// EDITOR >>>> https://editor.swagger.io/

const ApiPage = () => {
  const { api } = useParams();

  const apiConfig = availableAPIs[api] || availableAPIs[Object.keys(availableAPIs)[0]];

  if (api && apiConfig.key !== api) {
    return <Error404 />;
  }

  return (
    <MainLayout contentClassName="" menuSelectedKeys={[apiConfig.key]}>
      {/*<div className="p-4">*/}
      {/*  API: {api} {apiConfig.label}*/}
      {/*</div>*/}
      <div>
        <SwaggerUI spec={apiConfig.spec} />
      </div>
    </MainLayout>
  );
};

export default ApiPage;
