import React from 'react';
import MainLayout from '../components/Layout/mainLayout';

const Error404Page = () => {
  return (
    <MainLayout contentClassName="">
      <h1 className="p-4">Error 404. Page not found.</h1>
    </MainLayout>
  );
};

export default Error404Page;
