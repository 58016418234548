import instasentProduct from './openapi/instasent-product.json';
import instasentIngest from './openapi/instasent-ingest.json';
// import instasentTransactional from './openapi/instasent-transactional.json';
import instasentLegacy from './openapi/instasent-legacy.json';

export const availableAPIs = {
  product: {
    title: 'Instasent Product API',
    label: 'Product API',
    spec: instasentProduct,
    key: 'product',
  },
  ingest: {
    title: 'Instasent Ingest API',
    label: 'Ingest API',
    spec: instasentIngest,
    key: 'ingest',
  },
  // transactional: {
  //   title: 'Instasent Transactional API',
  //   label: 'Transactional API',
  //   spec: instasentTransactional,
  //   key: 'transactional',
  // },
  // legacy: {
  //   title: 'Instasent Legacy API',
  //   label: 'Legacy API',
  //   spec: instasentLegacy,
  //   key: 'legacy',
  // },
  transactional: {
    title: 'Instasent Transactional API',
    label: 'Transactional API',
    spec: instasentLegacy,
    key: 'transactional',
  },
};
